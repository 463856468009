// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use thes// that code so it'll be compiled.

import $ from 'jquery';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
const wx = require('weixin-js-sdk');
window.$ = $;
require('@rails/ujs').start();
require('@rails/activestorage').start();
dayjs.extend(duration);
window;
window.HUILIU = {
  getQuery() {
    const url = window.location.href;
    const queryObj = {};
    const queryStr = url.split('?')[1];
    if (queryStr) {
      const queryArr = queryStr.split('&');
      queryArr.forEach((item) => {
        const query = item.split('=');
        queryObj[query[0]] = query[1];
      });
    }
    return queryObj;
  },
  callFlutter(params) {
    try {
      let v = JSON.stringify(params);
      if (v.match(/^"/)) {
        v = v.replace(/"/g, '');
      }
      JavascriptChannel?.postMessage?.(v);
    } catch (e) {
      console.log(e);
    }
  },
  wx: wx,
  OI: undefined,
  dayjs,
  async downloadImage(url, filename) {
    try {
      // 使用fetch获取图片
      const response = await fetch(url);
      if (!response.ok) throw new Error(`Failed to fetch ${url}: ${response.statusText}`);
      const blob = await response.blob(); // 转换为blob

      // 创建一个隐藏的<a>标签，并设置其href为blob对象的URL
      const blobUrl = URL.createObjectURL(blob);
      const element = document.createElement('a');
      element.setAttribute('href', blobUrl);
      element.setAttribute('download', filename);

      // 将其隐藏并添加到文档中，然后触发点击事件来开始下载
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();

      // 清理：移除<a>标签，撤销blob URL
      document.body.removeChild(element);
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  },
  copyTextToClipboard(text) {
    return new Promise((resolve, reject) => {
      // 当navigator.clipboard API不可用时，使用textarea和document.execCommand()作为回退方案
      let textArea = document.createElement('textarea');
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        let successful = document.execCommand('copy');
        let msg = successful ? '成功' : '失败';
        console.log('回退方案：文本复制 ' + msg);
        resolve();
      } catch (err) {
        console.error('回退方案：无法复制文本', err);
        reject();
      }

      document.body.removeChild(textArea);
    });
  },
  showToast(message, duration = 3000) {
    // 创建Toast元素
    const toast = document.createElement('div');
    toast.textContent = message; // 设置Toast消息

    // 直接通过JavaScript设置样式
    Object.assign(toast.style, {
      visibility: 'hidden' /* 默认不显示 */,
      minWidth: '250px',
      marginLeft: '-125px' /* 宽度的一半，用于居中 */,
      backgroundColor: '#333',
      color: '#fff',
      textAlign: 'center',
      borderRadius: '2px',
      padding: '16px',
      position: 'fixed',
      zIndex: '1000',
      left: '50%',
      top: '50%',
      fontSize: '17px',
      transition: 'visibility 0s, opacity 0.5s linear' /* 平滑显示和隐藏 */,
    });

    // 添加到文档中
    document.body.appendChild(toast);

    // 设置动画以显示Toast
    setTimeout(() => {
      toast.style.visibility = 'visible';
      toast.style.opacity = '1';
    }, 100); // 稍微延迟以确保过渡效果生效

    // 设置定时器以隐藏和移除Toast
    setTimeout(() => {
      toast.style.opacity = '0';
      // 进一步延迟移除元素以让隐藏动画播放完毕
      setTimeout(() => document.body.removeChild(toast), 500); // 与过渡时间匹配
    }, duration);
  },
  popup: {
    show(content) {
      var popup = document.getElementById('popup');
      if (!popup) {
        // 创建弹窗容器
        var popup = document.createElement('div');
        popup.id = 'popup';
        popup.style.display = 'none';
        popup.style.position = 'fixed';
        popup.style.left = '0';
        popup.style.bottom = '0';
        popup.style.width = '100%';
        popup.style.height = '100%';
        popup.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
        popup.style.display = 'flex';
        popup.style.alignItems = 'center';
        popup.style.justifyContent = 'center';
        popup.style.zIndex = '999';
        popup.addEventListener('click', (e) => {
          window.HUILIU.popup.close();
        });

        // 创建弹窗内容部分
        var popupContent = document.createElement('div');
        popupContent.addEventListener('click', (e) => {
          e.stopPropagation();
        });
        popupContent.id = 'popup-content';
        popupContent.style.position = 'fixed';
        popupContent.style.bottom = '0';
        popupContent.style.backgroundColor = 'white';
        // popupContent.style.padding = '12px';
        popupContent.style.borderRadius = '6px 6px 0 0';
        popupContent.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';
        popupContent.style.width = '100%';

        popupContent.innerHTML = content;
        // 将内容添加到弹窗容器
        popup.appendChild(popupContent);

        // 将弹窗容器添加到文档体
        document.body.appendChild(popup);
      }
      popup.style.display = 'flex';
    },
    close() {
      var popup = document.getElementById('popup');
      popup.style.display = 'none';
    },
  },
};

import { Turbo } from '@hotwired/turbo-rails';
window.Turbo = Turbo;

// bootstrap
require('@popperjs/core/dist/umd/popper.js');
require('bootstrap/dist/js/bootstrap.bundle.js');

// slick
require('slick-carousel');
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { throttle, checkWX } from 'src/utils.js';

// customization
require('channels');
require('src/official_website');

import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

// import 'stylesheets/application';
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true);
const imagePath = (name) => images(name, true);

function copyFunction(e) {
  e.preventDefault();
  var copyText = $(e.target).data('text');
  const el = document.createElement('input');
  el.value = copyText;
  /* Get the text field */
  document.body.appendChild(el);
  /* Select the text field */
  el.select();
  el.setSelectionRange(0, 99999); /*For mobile devices*/

  /* Copy the text inside the text field */
  document.execCommand('copy');
  document.body.removeChild(el);

  $('.my-toast div').fadeIn();
  setTimeout(() => {
    $('.my-toast div').fadeOut();
  }, 2000);
}

function setActiveNav(target) {
  $('.navbar-nav .nav-link').removeClass('active');
  $('.navbar-nav .nav-link.' + target).addClass('active');
}

document.addEventListener('turbo:load', function () {
  $(document).on('click', '.click-to-copy', copyFunction);
  const initTarget = sessionStorage.getItem('target');
  setActiveNav(initTarget || 'intro');
  const targetList = ['intro', 'process', 'case', 'company', 'news'];
  const targetTopList = {};
  targetList.forEach(function (target) {
    const targetEle = document.querySelector('#' + target);
    if (targetEle) {
      const top = targetEle.offsetTop;
      targetTopList[target] = top;
    }
  });
  const handleScroll = throttle({
    handler() {
      const scrollTop = document.querySelector('html').scrollTop;
      Object.keys(targetTopList).forEach(function (key) {
        if (Math.abs(targetTopList[key] - scrollTop) <= 100 && document.querySelector(`#${key}`)) {
          setActiveNav(key);
          sessionStorage.setItem('target', key);
        }
      });
    },
    time: 50,
  });
  document.addEventListener('scroll', handleScroll);
  const header = document.querySelector('#header');
  if (header) {
    const headerHeight = header.getBoundingClientRect().height;
    document.querySelectorAll('.navbar-nav .nav-item .nav-link').forEach(function (ele) {
      ele.addEventListener('click', function (e) {
        const target = e.target.dataset.target;
        const targetEle = document.querySelector('#' + target);
        if (targetEle) {
          scrollToTarget(target, headerHeight);
        } else {
          window.location.href = `https://www.huiliu.net#${target}`;
        }
      });
    });
  }

  $('.button-backflow,.button-case').on('click', function (e) {
    const modal = document.querySelector('.modal-container');
    modal.style.display = 'flex';
    const type = e.target.dataset.type;
    modal.querySelector('.slot').innerHTML = e.target.innerHTML;
  });

  $('.modal-container').on('click', function (e) {
    document.querySelector('.modal-container').style.display = 'none';
  });

  wx.config({
    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    appId: gon.appid, // 必填，公众号的唯一标识
    timestamp: gon.timestamp, // 必填，生成签名的时间戳
    nonceStr: gon.nonceStr, // 必填，生成签名的随机串
    signature: gon.signature, // 必填，签名
    jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'], // 必填，需要使用的JS接口列表
    openTagList: ['wx-open-launch-app'],
  });

  wx.ready(function () {
    wx.updateAppMessageShareData({
      title: document.title, // 分享标题
      desc: '云长科技', // 分享描述
      link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      imgUrl: location.origin + gon.cover, // 分享图标
      success: function () {
        // 设置成功
        console.log('successfully');
      },
    });

    wx.updateTimelineShareData({
      title: document.title, // 分享标题
      desc: '云长科技', // 分享描述
      link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      imgUrl: location.origin + gon.cover, // 分享图标
      success: function () {
        // 设置成功
        console.log('successfully');
      },
    });

    var ua = navigator.userAgent.toLowerCase();
    if (checkWX()) {
      //ios的ua中无miniProgram，但都有MicroMessenger（表示是微信浏览器）
      wx.miniProgram.getEnv((res) => {
        if (res.miniprogram) {
          $('.header').hide();
          $('.footer').hide();
          $('.qa-wrapper .banner').addClass('miniprogram');
        }
      });
    }
  });
  function scrollToTarget(target, headerHeight) {
    const targetEle = document.querySelector('#' + target);
    const elePosition = targetEle.offsetTop;
    window.scrollTo({
      top: elePosition - headerHeight,
      behavior: 'smooth',
    });
    if (window.innerWidth <= 992) document.querySelector('.navbar-toggler').click();
  }
  function initMiniprogram() {
    $('#miniprogram-bidder-agree-button').on('click', function () {
      let url = '/pages/backflow/payment-of-deposit/payment-of-deposit';
      const query = window.HUILIU.getQuery && window.HUILIU.getQuery();
      if (query.direct) {
        url = url + `?direct=${query.direct}`;
      }
      wx.miniProgram.redirectTo({ url });
    });
  }
  function initDyEntry() {
    if (window.location.pathname.match('dy-entry')) {
      const query = window.HUILIU.getQuery();
      fetch(
        `https://sapi.wailian1.cn/api/urlscheme/dMr1iJJSS1m0AKA?sign=b1fb43e6022ddfcbab61c3b89676a0ab&status=json&path=${encodeURIComponent(
          `/pages/index/index${location.search || ''}`
        )}`
      )
        .then((res) => res.json())
        .then((data) => {
          const { scheme } = data.result;
          document.querySelector('.dy-entry-page a').setAttribute('href', scheme);
          document.querySelector('.dy-entry-page a').setAttribute('style', 'display:flex');
          document.querySelector('.dy-entry-page a').click();
        });
    }
  }
  Sentry.init({
    dsn: gon.sentry,
    // Alternatively, use `process.env.npm_package_version` for a dynamic release version
    // if your build tool supports it.
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0,
    environment: gon.environment,
  });

  initMiniprogram();
  initDyEntry();
});

import 'controllers';
