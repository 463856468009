import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "number", "mask" ]

  initialize() {
    this.timer = setInterval(() => {
      this.paidChecking()
    }, 1000)
  }

  stopTimer() {
    clearInterval(this.timer)
  }

  paidChecking() {
    const id = this.numberTarget.dataset.id
    const url = `/api/v1/orders/${id}/paid_checking`
    fetch(url, {
      headers: {
        'content-type': 'application/json'
      },
    }).then(res => {
      return res.json()
    }).then(res => {
      const mask = this.maskTarget

      if (res.is_paid) {
        this.stopTimer()
        mask.classList.add('active')

        setTimeout(() => {
          Turbo.clearCache()
          Turbo.visit("/backflows/store", { action: "replace" })
        }, 2000)
      }
    }).catch(error => {
      this.stopTimer()
    })
  }
}
