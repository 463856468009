import api from './api';
import { countDown } from './utils';
export default function init() {
  if (window.location.pathname == '/login') {
    let accept = false;
    function _(e) {
      countDown(e, (l, u) => {
        if (u == 'expired') {
          localStorage.removeItem('get-code-end');
          $('.login-container .code-input .get-code').text('获取验证码');
        } else {
          $('.login-container .code-input .get-code').text(`请${l[2]}秒后重试`);
        }
      });
    }
    const getCodeEnd = localStorage.getItem('get-code-end');
    const endDate = window.HUILIU.dayjs(getCodeEnd);
    const now = window.HUILIU.dayjs();
    if (getCodeEnd && endDate > now) {
      _(endDate);
    } else {
      localStorage.removeItem('get-code-end');
    }
    $('.login-container .code-input .get-code').on('click', () => {
      const _getCodeEnd = localStorage.getItem('get-code-end');
      if (_getCodeEnd) return;
      const mobile = $('.login-container .phone-input input').val();
      if (!mobile) {
        alert('请输入手机号');
        return;
      }
      api({
        url: `/api/v1/message_code`,
        data: {
          mobile,
        },
        method: 'POST',
      }).then((res) => {
        const end = window.HUILIU.dayjs().add(60, 'second').format('YYYY-MM-DD HH:mm:ss');
        localStorage.setItem('get-code-end', end);
        _(end);
      });
    });
    $('.login-container .login-button').on('click', () => {
      const mobile = $('.login-container .phone-input input').val();
      const code = $('.login-container .code-input input').val();
      if (!mobile) {
        alert('请输入手机号');
        return;
      }
      if (!code) {
        alert('请输入验证码');
        return;
      }
      if (!accept) {
        alert('请先同意用户协议和隐私条款');
        return;
      }
      api({
        url: `/api/v1/sessions/normal`,
        method: 'POST',
        data: {
          mobile,
          code,
        },
      }).then((res) => {
        sessionStorage.setItem('token', res.token);
        sessionStorage.setItem('user_id', res.user_id);
        window.open('/welfare', '_self');
      });
    });
    $('.login-container .terms').on('click', (e) => {
      e.stopPropagation();
      if ($(e.target).is('input')) {
        return;
      }
      setTimeout(() => {
        const val = $('.login-container .terms input').is(':checked');
        accept = val;
      }, 100);
    });
  }
}
