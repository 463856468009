import api from './api';
import { countDown, checkWX } from './utils';
export default function init() {
  if (window.location.pathname == '/welfare') {
    const query = window.HUILIU.getQuery();
    function initOldShow() {
      const query = window.HUILIU.getQuery && window.HUILIU.getQuery();
      if (query.status) {
        $('#welfare-' + query.status).css('display', 'flex');
      }
      $('#welfare-has_created_backflows').on('click', () => {
        window.HUILIU.callFlutter('onCreateBackflow');
        console.log('flutter run onCreateBackflow');
      });
      $('#welfare-has_shipped_backflows').on('click', () => {
        window.HUILIU.callFlutter('jumpToMyBackFlowsListPage');
        console.log('flutter run jumpToMyBackFlowsListPage');
      });
      $('#welfare-success').on('click', () => {
        window.HUILIU.callFlutter('jumpToWalletPage');
        console.log('flutter run jumpToWalletPage');
      });
    }
    function initNewShow() {
      if (query.token) {
        sessionStorage.setItem('token', query.token);
      }
      const userId = sessionStorage.getItem('user_id');
      const token = sessionStorage.getItem('token');
      let status = ''; //initial, wait_create, wait_send, wait_evaluation, success, failure, reward_expired, create_expired
      let expiredAt = '';
      if (!token) {
        const end = window.HUILIU.dayjs().add(7, 'day');
        show('initial');
        _('领取倒计时', end);
      } else {
        _init();
      }
      function _(text, end, onExpired) {
        $('.welfare-container .welfare-count-down').addClass('show');
        $('.welfare-container .welfare-count-down .text').text(text);
        countDown(end, (l, u) => {
          if (u == 'expired') {
            $('.welfare-container .welfare-count-down .count-down').text('已超时');
            onExpired?.();
          } else if (u == 'day') {
            $('.welfare-container .welfare-count-down .count-down').text(`${l[0]}天${l[1]}时${l[2]}分`);
          } else if (u == 'hour') {
            $('.welfare-container .welfare-count-down .count-down').text(`${l[0]}:${l[1]}:${l[2]}`);
          }
        });
      }
      async function getSignInDate() {
        const res = await api({
          url: '/api/v1/current',
          method: 'GET',
        });
        return res.created_at;
      }
      function _init() {
        api({
          url: '/api/v1/activities/present',
          method: 'GET',
        }).then((res) => {
          const { data } = res;
          if (!data.length) {
            status = 'initial';
          } else {
            const item = data[0];
            const { status: _status, extra, expired_at } = item;
            expiredAt = expired_at?.replace(/-/g, '/');
            const { has_created_backflows, has_evaluated_backflows, has_shipped_backflows } = extra;
            if (_status == 'pending') {
              if (!has_created_backflows) {
                status = 'wait_create';
              } else if (has_created_backflows && !has_shipped_backflows) {
                status = 'wait_send';
              } else if (has_shipped_backflows && !has_evaluated_backflows) {
                status = 'wait_evaluation';
              } else {
                status = 'initial';
              }
            } else if (_status == 'success') {
              status = 'success';
            } else if (_status == 'failure') {
              if (!has_created_backflows) {
                status = 'create_expired';
              } else {
                status = 'failure';
              }
            }
            console.log(_status, status);
          }
          handle();
        });
      }
      async function handle() {
        if (status == 'initial') {
          const date = await getSignInDate();
          const end = window.HUILIU.dayjs(date?.replace(/-/g, '/')).add(7, 'day');
          const now = window.HUILIU.dayjs();
          if (now >= end) {
            status = 'reward_expired';
            handle();
          } else {
            accept();
          }
        } else if (status == 'wait_create') {
          show('wait_create');
          _('任务倒计时', expiredAt, () => {
            status = 'create_expired';
            handle();
          });
        } else if (status == 'wait_send') {
          show('wait_send');
        } else if (status == 'wait_evaluation') {
          show('wait_evaluation');
        } else if (status == 'success') {
          show('success');
        } else if (status == 'failure') {
          show('failure');
        } else if (status == 'reward_expired') {
          disabled('initial');
          _('领取倒计时', '1970/01/01 00:00:00');
        } else if (status == 'create_expired') {
          disabled('wait_create');
          _('任务倒计时', '1970/01/01 00:00:00');
        }
      }
      function show(name) {
        $('.welfare-container .welfare-content').removeClass('show');
        $(`#welfare-${name}`).addClass('show');
      }
      function disabled(name) {
        $('.welfare-container .welfare-content .button').removeClass('disabled');
        $(`#welfare-${name}`).addClass('show').find('.button').addClass('disabled');
      }
      function onClick(name, cb) {
        $(`#welfare-${name} .button`).on('click', (e) => {
          if (checkWX()) {
            $('.welfare-container .mask').show();
          } else {
            if (!token) {
              window.open('/login', '_self');
            } else {
              if ([...e.target.classList].includes('disabled')) {
                return;
              }
              cb();
            }
          }
        });
      }
      function accept() {
        api({
          // url: '/api/v1/activities/2/join', //测试环境
          url: '/api/v1/activities/1/join', //生产环境
          method: 'POST',
        }).then((res) => {
          _init();
        });
      }
      onClick('initial', () => {
        accept();
      });
      $(`.welfare-container .mask`).on('click', () => {
        $('.welfare-container .mask').hide();
      });
      onClick('wait_create', () => {
        window.HUILIU.OI.wakeupOrInstall({
          data: {
            open_page: 'backflow_tab_page',
            userid: userId,
          },
        });
        window.HUILIU.callFlutter('onCreateBackflow');
      });
      onClick('wait_send', () => {
        window.HUILIU.OI.wakeupOrInstall({
          data: {
            open_page: 'my_back_flows_list_page_initial',
            userid: userId,
          },
        });
        window.HUILIU.callFlutter('jumpToMyBackFlowsListPage');
      });
      onClick('success', () => {
        window.HUILIU.OI.wakeupOrInstall({
          data: {
            open_page: 'wallet_account_list_page',
            userid: userId,
          },
        });
        window.HUILIU.callFlutter('jumpToWalletPage');
      });
    }
    if (query.status) {
      initOldShow();
    } else {
      initNewShow();
    }
  }
}
