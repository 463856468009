import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "area" ]

  initialize() {
    this.provinceSelector = document.getElementById('address_province_id')
    this.citySelector = document.getElementById('address_city_id')
  }

  connect() {
    this.provinceSelector.addEventListener('change', (e) => {
      this.citySelector.options.length = 0
      const cities = window.ADDRESS_MAP[e.target.value]
      Object.keys(cities).map((key) => {
        const option = new Option(cities[key], key)
        this.citySelector.options.add(option)
      })
      this.areaTarget.value = this.city_string().join('')
    })

    this.citySelector.addEventListener('change', (e) => {
      this.areaTarget.value = this.city_string().join('')
    })

    this.areaTarget.value = this.city_string().join('')
  }

  city_string() {
    const province = ADDRESS_MAP['86'][this.provinceSelector.value]
    const city = ADDRESS_MAP[this.provinceSelector.value][this.citySelector.value]
    return [province, city]
  }
}
