import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "radioList" ]

  initialize() {
    this.radioInputs = this.radioListTarget.querySelectorAll('input')
    const priceInformationTarget = document.querySelector('.price-information')
    this.shipmentPriceTarget = priceInformationTarget.querySelector('.shipment-price')
    this.totalPriceTarget = priceInformationTarget.querySelector('.total-price')
    this.backflowsPriceTarget = priceInformationTarget.querySelector('.backflows-price')
  }

  connect() {
    this.radioInputs.forEach(item => {
      item.addEventListener('change', event => {
        const price = event.target.dataset.price
        const currency = '¥'
        const backflowPrice = this.backflowsPriceTarget.dataset.price
        this.shipmentPriceTarget.textContent = currency + price
        this.totalPriceTarget.textContent = currency + (parseFloat(backflowPrice) + parseFloat(price))
      })
    })
  }
}
