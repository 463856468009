import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['fetchButton', 'chronograph', 'termChecker', 'submitButton']

  initialize() {
    this.chronographTarget.classList.add('d-none')
    this.originContent = this.chronographTarget.textContent
    this.submitButtonTarget.disabled = !this.termCheckerTarget.checked
  }

  fetchCode(e) {
    const that = this
    e.preventDefault()
    const codeButton = document.querySelector('.fetch_code')
    const url = e.target.dataset['url']
    const mobileInput = document.getElementsByName('mobile')[0]
    const mobile = mobileInput.value

    fetch(url, {
      method: 'POST',
      body: JSON.stringify({ mobile }),
      headers: {
        'content-type': 'application/json'
      },
    }).then(res => {
        this.toggleButtons()
        this.setTimer()
      })
      .catch(error => console.error(error))
  }

  toggleButtons(e) {
    if (this.chronographTarget.classList.contains('d-none')) {
      this.chronographTarget.classList.remove('d-none')
      this.fetchButtonTarget.classList.add('d-none')
    } else {
      this.chronographTarget.classList.add('d-none')
      this.fetchButtonTarget.classList.remove('d-none')
    }
  }

  setChronographText() {
    this.chronographTarget.textContent = this.originContent + this.second + 's'
  }

  setTimer() {
    const that = this
    that.second = 60
    that.setChronographText()

    that.timer = setInterval(() => {

      if(!that.second) {
        clearInterval(that.timer)
        that.toggleButtons()
      }

      that.second -= 1
      that.setChronographText()
    }, 1000)
  }

  checkboxChange() {
    this.submitButtonTarget.disabled = !this.termCheckerTarget.checked
  }
}
