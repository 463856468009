import { throttle, checkWX } from 'src/utils.js';
import initLogin from './login';
import initWelfare from './welfare';
import initRight from './rights';
import initProcess from './process';
import initDoorToDoor from './door_to_door';
document.addEventListener('turbo:visit', function () {
  // 先卸载
  $('.special-carousel').filter('.slick-initialized').slick('unslick');
  $('.product-part .carousel').filter('.slick-initialized').slick('unslick');
});

document.addEventListener('turbo:load', function () {
  const OFFICIAL_DATA = {
    isScroll: false,
    showBackToTop: false,
    aboutCarousel: null,
  };
  const handleScroll = throttle({
    handler() {
      const scrollTop = $(document).scrollTop();
      const threshold = 30;
      if (scrollTop > threshold && !OFFICIAL_DATA.isScroll) {
        OFFICIAL_DATA.isScroll = true;
        $('.header').addClass('scroll');
      } else if (scrollTop <= threshold && OFFICIAL_DATA.isScroll) {
        OFFICIAL_DATA.isScroll = false;
        $('.header').removeClass('scroll');
      }
    },
    time: 10,
  });
  const handleShowBackToTop = throttle({
    handler() {
      const scrollTop = $(document).scrollTop();
      const threshold = 300;
      if (scrollTop > threshold && !OFFICIAL_DATA.showBackToTop) {
        OFFICIAL_DATA.showBackToTop = true;
        $('.backtotop-button').addClass('show');
      } else if (scrollTop <= threshold && OFFICIAL_DATA.showBackToTop) {
        OFFICIAL_DATA.showBackToTop = false;
        $('.backtotop-button').removeClass('show');
      }
    },
  });
  function init() {
    // var vConsole = new VConsole();
    handleScroll();
    const query = window.HUILIU.getQuery && window.HUILIU.getQuery();
    if (query.hide_confirm_btn) {
      $('#miniprogram-bidder-agree-button').hide();
    }
    document.addEventListener('scroll', (e) => {
      handleScroll(e);
      handleShowBackToTop(e);
    });

    $('.navbar-toggler').on('click', () => {
      const el = $('.header');
      const isDrop = $('.navbar-collapse.show').length;
      //放到微任务里
      setTimeout(() => {
        if (isDrop) {
          el.removeClass('drop');
        } else {
          el.addClass('drop');
        }
      });
    });
    $('.backtotop-button').on('click', () => {
      handleBackToTop();
    });
    $('.android-download').on('click', () => {
      window.open('https://huiliu.net/huiliu-app.apk');
    });
    $('.ios-download').on('click', () => {
      window.open('https://apps.apple.com/cn/app/%E5%9B%9E%E6%B5%81/id1581519711');
    });
    initSlick();
    initOpenInstall();
    initAppDownload();
    // setTimeout(() => {
    // initWelfareShow();
    initLogin();
    initWelfare();
    initRight();
    initProcess();
    initDoorToDoor();
    // }, 200);
  }
  function initSlick() {
    if ($('.product-part .carousel').length) {
      $('.product-part .carousel')
        .not('.slick-initialized')
        .slick({
          slidesToShow: 4,
          slidesToScroll: 4,
          responsive: [
            {
              breakpoint: 992,
              settings: {
                variableWidth: true,
                arrows: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
              },
            },
          ],
        });
    }

    if ($('.special-carousel').length) {
      function setSpecialInfo(index) {
        const $item = $(`.special-carousel .item-${index}`);
        const realname = $item.data('realname');
        const intro = $item.data('intro');
        const $infoWrapper = $('.special-info');

        $infoWrapper.find('h3').text(realname);
        $infoWrapper.find('p').text(intro);
      }

      const currentSlide = $('.special-carousel')
        .not('.slick-initialized')
        .slick({
          infinite: false,
          variableWidth: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          arrows: true,
          responsive: [
            {
              breakpoint: 992,
              settings: {
                arrows: false,
              },
            },
          ],
        })
        .on('afterChange', (e, s, c) => {
          setSpecialInfo(c);
        }); //event,slick,current
      //初始化后获取当前索引
      let index = currentSlide.slick('slickCurrentSlide');
      const length = $('.special-carousel-item').length;
      if (length) {
        index = parseInt(length / 2);
        currentSlide.slick('slickGoTo', index, true);
      }
      setSpecialInfo(index);
    }
  }
  function initOpenInstall() {
    try {
      convertStyle('.download-wrapper');
      const query = OpenInstall.parseUrlParams();
      const { channelCode } = query;
      const prompt = document.querySelector('.wx-prompt');
      if (prompt) {
        prompt.addEventListener('click', () => {
          prompt.setAttribute('style', 'display:none');
        });
        if (checkWX()) {
          prompt.setAttribute('style', 'display:block');
        }
      }
      window.HUILIU.OI = new OpenInstall({
        appKey: 'lpni6x',
        channelCode,
      });
    } catch (e) {
      console.log(e);
    }
  }
  function initAppDownload() {
    const buttonDownload = document.querySelector('#app-download-button');
    if (buttonDownload) {
      buttonDownload.addEventListener('click', () => {
        const query = OpenInstall.parseUrlParams();
        const { referrer_id, douyin_channel_id } = query;
        window.HUILIU.OI.wakeupOrInstall({
          data: {
            referrer_id,
            douyin_channel_id,
          },
        });
      });
    }
  }
  function handleBackToTop() {
    let scrollTop = $(document).scrollTop();
    if (scrollTop) {
      $(document).scrollTop(scrollTop - 150);
      requestAnimationFrame(handleBackToTop);
    }
  }
  function convertStyle(selector) {
    const el = document.querySelector(selector);
    if (el) el.style.setProperty('height', `${window.innerHeight}px`);
  }
  init();
});
