import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "uploader" ]

  initialize() {
    this.uploaderTarget.onchange = e => {
      const [file] = this.uploaderTarget.files;

      if (file) {
        const blah = document.getElementById('blah')
        blah.src = URL.createObjectURL(file);
        blah.style.display = 'block'
      }
    }
  }
}
