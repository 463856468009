import { getBaseUrl } from './utils';
export default function api({ url, data, method }) {
  const token = sessionStorage.getItem('token');
  return new Promise((resolve, reject) => {
    $.ajax({
      url: `${getBaseUrl()}${url}`,
      data,
      headers: {
        'X-Authorization': token,
        'X-MINI-PROGRAM-VERSION': '6.0.0',
      },
      method,
      statusCode: {
        401(res) {
          const { responseJSON } = res;
          if (responseJSON.expired) {
            sessionStorage.removeItem('token');
            location.reload();
          }
        },
      },
      success(res) {
        resolve(res);
      },
      error(err) {
        alert(err.responseJSON.msg);
        reject(err);
      },
    });
  });
}
