export function throttle({ handler, time }) {
  let timp;
  return function () {
    if (!timp) {
      handler(...arguments);
      timp = setTimeout(() => {
        timp = null;
      }, time);
    }
  };
}
export function debounce({ handler, time }) {
  let timp;
  return function () {
    if (timp) {
      clearTimeout(timp);
    }
    timp = setTimeout(() => {
      handler(...arguments);
    }, time);
  };
}
export function checkWX() {
  const ua = navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger|micromessenger|miniprogram|miniProgram/)) {
    //ios的ua中无miniProgram，但都有MicroMessenger（表示是微信浏览器）
    return true;
  } else {
    return false;
  }
}
export function checkApp() {
  const ua = navigator.userAgent.toLowerCase();
  if (ua.match(/flutter-app/)) {
    return true;
  } else {
    return false;
  }
}
export function countDown(end, cb) {
  const _end = window.HUILIU.dayjs(end);
  const _now = window.HUILIU.dayjs();
  function formatTime(time) {
    return time >= 10 ? time.toString() : '0' + time;
  }
  function getDur(dur, time, _unit) {
    return dur.subtract(time, _unit);
  }
  function getUnit(now) {
    const expired_time = window.HUILIU.dayjs.duration(_end.diff(now)).asHours();
    if (expired_time <= 0) {
      return 'expired';
    } else if (expired_time > 24) {
      return 'day';
    } else {
      return 'hour';
    }
  }
  const formatMap = {
    hour(dur) {
      let hours = Math.floor(dur.asHours());
      const minDur = getDur(dur, hours, 'hours');
      let minutes = Math.floor(minDur.asMinutes());
      const secDur = getDur(minDur, minutes, 'minutes');
      let seconds = Math.floor(secDur.asSeconds());
      return [formatTime(hours), formatTime(minutes), formatTime(seconds)];
    },
    day(dur) {
      let days = Math.floor(dur.asDays());
      const hourDur = getDur(dur, days, 'days');
      let hours = Math.floor(hourDur.asHours());
      const minDur = getDur(hourDur, hours, 'hours');
      let minutes = Math.floor(minDur.asMinutes());
      return [days.toString(), formatTime(hours), formatTime(minutes)];
    },
    expired() {
      return ['00', '00', '00'];
    },
  };

  if (_end > _now) {
    const unit = getUnit(_now);
    const diff = _end.diff(_now);
    const duration = window.HUILIU.dayjs.duration(diff);
    cb?.(formatMap[unit]?.(duration), unit);
    const stamp = setInterval(() => {
      const __now = window.HUILIU.dayjs();
      const _unit = getUnit(__now);
      if (['day', 'hour'].includes(_unit)) {
        const _diff = _end.diff(__now);
        const _duration = window.HUILIU.dayjs.duration(_diff);
        cb?.(formatMap[_unit]?.(_duration), _unit);
      } else {
        clearInterval(stamp);
        cb?.(['00', '00', '00'], 'expired');
      }
    }, 1000);
  } else {
    cb?.(['00', '00', '00'], 'expired');
  }
}

export function getBaseUrl() {
  // return 'https://dev.huiliu.net';
  return 'https://api.huiliu.net';
}
